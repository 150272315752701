<template>
	<component :is="tag" :class="['title-' + tag, mod, 'xs']" v-html="content">
		<slot />
	</component>
</template>

<script>
export default {
	name: "Title",
	props: {
		tag: {
			type: String,
			default: "",
		},
		mod: {
			type: String,
			default: "",
		},
		content: {
			type: String,
			default: "",
		},
	},
	data: function () {
		return {};
	},
};
</script>

<style lang="scss">
@import "~vuetify/src/styles/settings/_variables";

h1,
h2,
h3,
h4,
h5 {
	color: $color-white;
	font-family: $font-extrabold;
	letter-spacing: $letter-spacing-03;
	margin: 0;
}

h1 {
	font-size: 110px;
	line-height: 121px;
	letter-spacing: $letter-spacing-04;
	margin-bottom: 5px;

	&.hidden {
		display: none;
	}

	&.small {
		font-size: 62px;
		line-height: 68px;
		margin-bottom: 50px;
	}
}

h2 {
	font-size: 62px;
	line-height: 68px;
	margin-bottom: 50px;

	.convert-report & {
		max-width: 781px;
	}

	&.small {
		font-size: 40px;
		line-height: 1.2;
		margin-bottom: 0.6em;
	}
}

h3 {
	font-size: 30px;
	line-height: 1.2;
	margin-bottom: 0.7em;
}

@media (max-width: #{map-get($grid-breakpoints, 'md')}) {
	h1 {
		font-size: 100px;
		line-height: 110px;
	}
}

@media (max-width: #{map-get($grid-breakpoints, 'xs')}) {
	h1 {
		font-size: 40px;
		line-height: 44px;
		margin-bottom: 14px;

		&.small {
			font-size: 32px;
			line-height: 35px;
			margin-bottom: 32px;
		}
	}

	h2 {
		font-size: 32px;
		line-height: 35px;
		margin-bottom: 32px;

		&.small {
			font-size: 26px;
		}
	}

	h3 {
		font-size: 21px;
	}
}
</style>
