import userService from "@/services/user.js";

export default {
	namespaced: true,
	state: {
		userData: null,
	},
	mutations: {
		SET_USER: (state, user) => {
			state.userData = user;
		},
	},
	actions: {
		async setUser({ commit }) {
			try {
				const { user } = await userService.getUser();

				if (user !== null) commit("SET_USER", user);
			} catch (error) {
				commit("noty/NEW", { type: "error", message: error.message }, { root: true });
			}
		},
	},
};
