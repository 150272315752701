<template>
	<v-app :class="{ 'menu-opened': menuOpened }">
		<noty-list />
		<v-app-bar app height="auto">
			<router-link to="/" class="mr-auto logo">
				<img src="@/assets/logo.svg" style="width: 100%" />
			</router-link>

			<v-tabs :optional="true" v-if="!isMobileView" slider-size="0" centered>
				<v-tab v-for="link in menuLinks" :key="link.to" :to="link.to">
					{{ link.text }}
				</v-tab>
			</v-tabs>

			<router-link
				:key="link.to"
				:to="link.to"
				:ripple="false"
				v-if="!isMobileView"
				class="theme--dark v-btn v-btn--rounded v-btn--outlined v-size--small v-btn--convert"
			>
				{{ link.text }}
			</router-link>
			<user></user>
			<v-app-bar-nav-icon @click.stop="drawer = !drawer" v-if="isMobileView" class="v-btn--burger">
				<v-icon v-if="!drawer">$burger</v-icon>
				<v-icon v-if="drawer">$close</v-icon>
			</v-app-bar-nav-icon>
		</v-app-bar>

		<v-navigation-drawer
			v-if="isMobileView"
			v-model="drawer"
			fixed
			temporary
			floating
			width="100%"
			height="auto"
			clipped-top="20px"
			overlay-color="#000"
			overlay-opacity=".6"
		>
			<v-list nav dense>
				<v-list-item-group active-class="primary--text">
					<v-list-item v-for="link in menuLinks" :key="link.to" :to="link.to">
						<v-list-item-title>{{ link.text }}</v-list-item-title>
					</v-list-item>
					<router-link
						:key="link.to"
						:to="link.to"
						:ripple="false"
						class="theme--dark v-btn v-btn--rounded v-btn--outlined v-size--small v-btn--convert"
					>
						{{ link.text }}
					</router-link>
				</v-list-item-group>
			</v-list>
		</v-navigation-drawer>

		<v-main>
			<v-container>
				<router-view />
			</v-container>
		</v-main>

		<cookie-noty></cookie-noty>
		<footer-main></footer-main>
	</v-app>
</template>

<script>
import FooterMain from "@/components/FooterMain.vue";
import User from "@/components/User.vue";
import CookieNoty from "@/components/CookieNoty.vue";
import NotyList from "@/components/noty/List.vue";

export default {
	name: "App",

	components: {
		FooterMain,
		User,
		CookieNoty,
		NotyList,
	},

	data() {
		return {
			drawer: false,
			menuOpened: false,
			menuOpenedTO: null,
			menuLinks: [
				{
					to: "/",
					text: "About Service",
				},
				{
					to: "/faq",
					text: "FAQ & Support",
				},
				// {
				// 	to: "/news",
				// 	text: "News & Updates",
				// },
				{
					to: "/contacts",
					text: "Contacts",
				},
			],
			link: {
				to: "/convert-report",
				text: "Convert report",
			},
		};
	},

	computed: {
		isMobileView() {
			return this.$vuetify.breakpoint.xs;
		},
	},
	watch: {
		drawer() {
			if (this.drawer) {
				this.menuOpened = true;
				clearTimeout(this.menuOpenedTO);
			} else {
				this.menuOpenedTO = setTimeout(() => (this.menuOpened = false), 200);
			}
		},
	},
};
</script>

<style lang="scss">
@import "~vuetify/src/styles/settings/_variables";

.v-application {
	.container,
	.v-toolbar__content,
	.v-footer__content {
		max-width: 1200px;
		margin: 0 auto;
		padding: 0 20px;
	}

	.v-toolbar__content {
		padding: 15px 20px;
	}
}

.v-application--wrap {
	overflow: hidden auto;
}

.v-app-bar__nav-icon.v-btn.theme--dark {
	padding: 0;
}

.v-alert {
	position: fixed !important;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	width: auto !important;
}

.menu-opened .v-overlay {
	height: calc(100vh - 40px);
	top: 40px;
	z-index: 20;
}

body,
html,
.v-application {
	font-family: $font, sans-serif;
	letter-spacing: $letter-spacing-01;
}

.v-main {
	padding-top: 50px;
}

@media (max-width: #{map-get($grid-breakpoints, 'lg')}) {
	.v-application {
		.container,
		.v-toolbar__content,
		.v-footer__content {
			max-width: 100%;
		}

		.v-toolbar__content {
			padding: 20px 20px 15px;
		}
	}

	.v-main {
		padding-top: 55px;
	}
}

@media (max-width: #{map-get($grid-breakpoints, 'md')}) {
	.theme--dark.v-btn.v-btn.v-size--small.v-btn--convert {
		font-size: 11px;
		padding: 0 16px;
	}
}

@media (max-width: #{map-get($grid-breakpoints, 'sm')}) {
	.theme--dark.v-btn.v-btn.v-size--small.v-btn--convert {
		font-size: 9px;
		padding: 0 10px;
		min-height: 32px;
	}
}

@media (max-width: #{map-get($grid-breakpoints, 'xs')}) {
	.v-application {
		.container,
		.v-toolbar__content,
		.v-footer__content {
			padding: 0 10px;
		}
	}

	.v-main {
		padding-top: 25px;
	}
}
</style>
