import $store from "../store";
import orderService from "@/services/order.js";

/**
 * Current user state initialization
 */
export async function initUser(to, from, next) {
	const user = $store.state.user.userData;

	if (user === null) {
		try {
			await $store.dispatch("user/setUser");
			next();
		} catch (e) {
			console.error(e);
		}
	} else {
		next();
	}
}

/**
 * Check user access rights to page
 */
export function checkAccess(to, from, next) {
	const user = $store.state.user.userData;
	const isAuthRequired = to.matched.some((item) => item.meta.requireAuth);
	const isAuthRedir = to.matched.some((item) => item.meta.redirAuth);

	if (isAuthRequired && user) return next();
	if (isAuthRequired) return next({ name: "login" });
	if (user && isAuthRedir) return next({ name: "main" });
	next();
}

/**
 * Check success page data (props) and access
 */
export function successPageAccess(to, from, next) {
	const user = $store.state.user.userData;
	const { orderId, convertId } = to.query;

	if (to.params.downloadData) {
		to.meta.downloadData = to.params.downloadData;
		to.meta.email = user.email;
		return next();
	} else if (orderId && convertId) {
		orderService
			.success(orderId, convertId)
			.then(async (downloadData) => {
				await $store.dispatch("user/setUser");
				to.meta.downloadData = downloadData;
				to.meta.email = user.email;
				to.meta.fromPaid = true;
				return next();
			})
			.catch(() => {
				if (user) {
					return next({ name: "conversions" });
				}
				next("/");
			});
	} else {
		if (user) {
			return next({ name: "conversions" });
		}
		next("/");
	}
}
