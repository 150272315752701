<template>
	<div v-if="showIt" class="cookie">
		<p class="cookie__title">Cookie information</p>
		<p class="cookie__text">
			This website uses cookies. Some are necessary for the operation, and some are optional and
			designed to&nbsp;improve your experience. Click “Accept” to&nbsp;accept the cookies. You
			can, of&nbsp;course, change your Cookie settings at&nbsp;any time by&nbsp;adjusting your
			browser.
		</p>
		<ul class="cookie__buttons">
			<li class="cookie__button">
				<button class="cookie__button--agree theme--dark v-btn v-btn--outlined" @click="acceptClose">
					Accept
				</button>
			</li>
			<li class="cookie__button">
				<button class="cookie__button--agree theme--dark v-btn v-btn--outlined" @click="acceptClose">
					Decline
				</button>
			</li>
			<li class="cookie__button">
				<router-link
					to="/cookie-policy"
					class="cookie__button--read-more theme--dark v-btn v-btn--outlined"
				>
					Read more
				</router-link>
			</li>
		</ul>
	</div>
</template>

<script>
import cookies from "@/utils/cookie.js";
export default {
	name: "CookieNoty",

	COOKIE_NAME: "cookieAccepted",

	data() {
		return {
			cookieValue: cookies.getCookie(this.$options.COOKIE_NAME),
		};
	},
	computed: {
		showIt() {
			return !this.cookieValue && !this.isCookiePage;
		},
		isCookiePage() {
			return this.$route.path.includes("/cookie-policy");
		},
	},
	methods: {
		removeLock() {
			document.body.classList.remove("body-locked");
		},
		setCookieValue(val) {
			cookies.setCookie(this.$options.COOKIE_NAME, val);
			this.cookieValue = cookies.getCookie(this.$options.COOKIE_NAME);
		},
		acceptClose() {
			this.removeLock();
			this.setCookieValue(1);
		},
	},
	updated() {
		if (this.showIt) document.body.classList.add("body-locked");
		if (this.isCookiePage) this.removeLock();
	},
	destroyed() {
		this.removeLock();
	},
};
</script>

<style lang="scss" scoped>
.cookie {
	position: fixed;
	bottom: 0;
	right: 0;
	padding: 32px;
	width: 550px;

	background-color: $color-black-russian;
	z-index: 7;

	&__title {
		font-weight: 700;
		font-size: 18px;
		line-height: 30px;

		color: $color-white;
	}

	&__text {
		font-size: 14px;
		line-height: 26px;

		color: $color-white;
	}

	&__buttons {
		display: flex;
		padding: 0 !important;
		list-style: none;
	}

	&__button {
		margin-right: 20px;

		&:last-child {
			margin-right: 0;
		}

		button,
		a {
			font-size: 13px !important;
			line-height: 13px !important;
			padding: 13px 20px !important;
			min-height: auto !important;
			height: 100%;
		}
	}

	@media (max-width: #{map-get($grid-breakpoints, 'xs')}) {
		width: 100%;

		&__text,
		.link {
			font-size: 12px !important;
		}
	}
}
</style>
