import Vue from "vue";
import Router from "vue-router";
import { initUser, checkAccess } from "./middlewares";
import routes from "./routes";
import VueMeta from "vue-meta";

const TITLE_BASE = "XHTML.REPORT";
const getFullTitle = (routeTitle) => {
	return routeTitle ? `${routeTitle} - ${TITLE_BASE}` : TITLE_BASE;
};

Vue.use(Router);
Vue.use(VueMeta);

const router = new Router({
	mode: "history",
	routes,
	scrollBehavior() {
		return { x: 0, y: 0 };
	},
});

router.beforeEach(initUser);
router.beforeEach(checkAccess);
router.afterEach(({ meta }) => {
	Vue.nextTick(() => {
		document.title = getFullTitle(meta.title);
	});
});

export default router;
