export default {
	namespaced: true,
	state: {
		notyList: [],
	},

	mutations: {
		NEW(state, payload) {
			const isError = payload.type === "error";
			const noty = {
				id: new Date().getTime(),
				message: isError
					? "Something went wrong. Please contact support"
					: typeof payload === "string"
					? payload
					: payload.message,
				type: payload.type || "default",
				duration: payload.duration || 5000,
				temp: payload.temp, // TEMP
			};
			if (isError) {
				console.error(payload.message);
			}
			state.notyList.push({ ...noty });
		},
		REMOVE(state, payload) {
			state.notyList = state.notyList.filter(({ id }) => id !== payload.id);
		},
		CLEAR_LIST(state) {
			state.notyList = [];
		},
	},
};
