<template>
	<div
		v-if="item.temp"
		class="noty"
		:class="classList"
		style="max-width: 100vw; width: 360px"
		@click="remove"
	>
		<!-- TEMP -->
		<span style="display: block">
			The free trial period for users is&nbsp;over. However, here is&nbsp;some good news&nbsp;—
			xhtml.report subscription plan will soon be&nbsp;available!
		</span>
		<span style="display: block" class="mt-2">Stay tuned! We&nbsp;will keep you informed.</span>
		<span style="display: block" class="mt-2">
			For now, the agents can get access to&nbsp;this service. If&nbsp;you have any questions,
			please contact&nbsp;us at&nbsp;<a href="mailto:support@xhtml.report">support@xhtml.report</a>.
		</span>
	</div>
	<div v-else class="noty" :class="classList" @click="remove">
		{{ item.message }}
	</div>
</template>

<script>
export default {
	name: "Noty",
	props: {
		item: {
			type: Object,
		},
	},

	methods: {
		remove() {
			this.$emit("remove", this.item.id);
		},
	},

	computed: {
		classList() {
			return {
				"n-default": this.item.type === "default",
				"n-success": this.item.type === "success",
				"n-info": this.item.type === "info",
				"n-warning": this.item.type === "warning",
				"n-error": this.item.type === "error",
			};
		},
	},

	mounted() {
		if (this.item.duration) {
			const timer = setTimeout(() => {
				clearTimeout(timer);
				this.remove();
			}, this.item.duration);
		}
	},
};
</script>

<style lang="scss">
.noty {
	cursor: pointer;
	padding: 20px;
	background-color: $color-jaguar4;
	color: $color-white;
	margin: 16px 0;
	border-radius: 10px;

	&.n-default {
		border-top: 1px solid $color-wistful;
	}
	&.n-success {
		border-top: 1px solid $color-christi;
	}
	&.n-info {
		border-top: 1px solid $color-slate-blue;
	}
	&.n-warning {
		border-top: 1px solid $color-tangerine;
	}
	&.n-error {
		border-top: 1px solid $color-err;
	}
}
</style>
