import axios from "axios";

const API_ROOT = "/api/v1/user";
const ep = {
	user: `${API_ROOT}`,
};

async function getUser() {
	try {
		const res = await axios.get(ep.user);
		return res.data;
	} catch (error) {
		const message = error.response.data ? error.response.data.message : error.response.statusText;
		throw new Error(message);
	}
}

export default { getUser };
