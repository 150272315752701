import axios from "axios";
import $store from "../store";
import $router from "../router";

const API_ROOT = "/api/v1/auth";
const ep = {
	sendCode: `${API_ROOT}/send-verify`,
	sign: `${API_ROOT}/sign`,
	logout: `${API_ROOT}/logout`,
	refreshToken: `${API_ROOT}/refresh-tokens`,
};

async function sendCode({ email }) {
	try {
		const res = await axios.post(ep.sendCode, { email });
		return res.data.expireTime;
	} catch (error) {
		const message = error.response.data ? error.response.data.message : error.response.statusText;
		const err = new Error(message);
		err.code = error.response.status;
		throw err;
	}
}

async function sign({ email, code }) {
	try {
		const res = await axios.post(ep.sign, { email, code }, { withCredentials: true });
		return res.data;
	} catch (error) {
		const message = error.response.data ? error.response.data.message : error.response.statusText;
		throw new Error(message);
	}
}

function refreshToken() {
	return axios.post(ep.refreshToken);
}

async function logout() {
	await axios.post(ep.logout, { withCredentials: true });
	$store.commit("user/SET_USER", null);
	$router.push({ name: "main" }).catch(() => {});
}

export default { sendCode, sign, refreshToken, logout };
