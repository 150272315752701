<template>
	<transition-group name="slide" tag="div" class="noty-list">
		<Noty @remove="onRemove" v-for="item in notyList" :item="item" :key="item.id" />
	</transition-group>
</template>

<script>
import Noty from "./Noty";
export default {
	name: "NotyList",

	components: {
		Noty,
	},

	watch: {
		$route: {
			handler: "clearNotyList",
			deep: "true",
		},
	},

	computed: {
		notyList() {
			return this.$store.state.noty.notyList;
		},
	},

	methods: {
		onRemove(id) {
			this.$store.commit("noty/REMOVE", { id });
		},
		clearNotyList() {
			if (this.notyList.length) {
				this.$store.commit("noty/CLEAR_LIST");
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.noty-list {
	top: 0;
	left: 0;
	right: 0;
	width: 300px;
	margin: auto;
	position: fixed;
	z-index: 9999;
	opacity: 1;
}

.slide-leave-active,
.slide-enter-active {
	transition: 0.3s;
}
.slide-enter {
	opacity: 0;
	transform: translate(0, 100%);
}
.slide-leave-to {
	// transform: translate(0, -100%);
	transform: translate(-100%, 0);
	opacity: 0;
}
</style>
