<template>
	<v-footer outlined>
		<v-card flat tile color="transparent" class="v-footer__content text-center" style="width: 100%">
			<v-card-text class="d-flex flex-column flex-sm-row align-center pa-0">
				<!-- <div class="py-2 py-sm-0">&copy; {{ new Date().getFullYear() }} <strong>P2X</strong></div>
				<v-spacer></v-spacer> -->
				<router-link
					v-for="link in links"
					:key="link.to"
					:to="link.to || { name: link.name }"
					class="link link--nav"
				>
					{{ link.text }}
				</router-link>
				<a
					class="link link--nav link--bright ml-sm-auto"
					href="https://fanda.company"
					target="_blank"
					>© Fanda</a
				>
			</v-card-text>
		</v-card>
	</v-footer>
</template>

<script>
export default {
	data: () => ({
		links: [
			{
				name: "terms-conditions",
				text: "Privacy policy",
			},
			{
				name: "cookie-policy",
				text: "Cookie policy",
			},
			{
				name: "terms-conditions-of-use",
				text: "Terms & Conditions of Use",
			},
			// {
			// 	to: "/technical-requirements",
			// 	text: "Technical requirements",
			// },
			// {
			// 	to: "/contacts",
			// 	text: "Contacts",
			// },
		],
	}),
};
</script>
