import axios from "axios";

const API_ROOT = "/api/v1/order";
const ep = {
	orders: `${API_ROOT}`,
	demoCount: `${API_ROOT}/demo-count`,
	create: `${API_ROOT}/create`,
	uploadConvert: `${API_ROOT}/upload-convert`,
	success: `${API_ROOT}/success`,
	checkout: `${API_ROOT}/checkout`,
	periodOrder: `${API_ROOT}/period`,
	orderFeedback: `${API_ROOT}/feedback`,
	downloadInvoice: `${API_ROOT}/download-invoice`,
	invoiceData: `${API_ROOT}/invoice-data`,
};

async function getOrders() {
	try {
		const res = await axios.get(ep.orders);
		return res.data;
	} catch (error) {
		const message = error.response.data ? error.response.data.message : error.response.statusText;
		throw new Error(message);
	}
}

async function getDemoOrdersCount() {
	try {
		const res = await axios.get(ep.demoCount);
		return res.data.count;
	} catch (error) {
		const message = error.response.data ? error.response.data.message : error.response.statusText;
		throw new Error(message);
	}
}

async function create(data) {
	try {
		const res = await axios.post(ep.create, data);
		return res.data;
	} catch (error) {
		const message = error.response.data ? error.response.data.message : error.response.statusText;
		throw new Error(message);
	}
}

async function uploadConvert(filesData, progressCb) {
	try {
		const options = {
			onUploadProgress: (event) => {
				const progress = Math.round((event.loaded * 100) / event.total);
				progressCb(progress);
			},
		};
		const res = await axios.post(ep.uploadConvert, filesData, options);
		return res.data;
	} catch (error) {
		const message = error.response.data ? error.response.data.message : error.response.statusText;
		throw new Error(message);
	}
}

function checkout(orderId, convertId) {
	try {
		return (
			axios
				// .get(ep.checkout, { params: { orderId, convertId } })
				.post(ep.checkout, { orderId, convertId })
				.then(({ data }) => data.paymentUrl)
		);
	} catch (error) {
		const message = error.response.data ? error.response.data.message : error.response.statusText;
		throw new Error(message);
	}
}

function success(orderId, convertId) {
	try {
		return axios.get(ep.success, { params: { orderId, convertId } }).then(({ data }) => data);
	} catch (error) {
		const message = error.response.data ? error.response.data.message : error.response.statusText;
		throw new Error(message);
	}
}

async function getPeriodOrder() {
	try {
		const res = await axios.get(ep.periodOrder);
		return res.data;
	} catch (error) {
		const message = error.response.data ? error.response.data.message : error.response.statusText;
		throw new Error(message);
	}
}

async function sendFeedback(messageData) {
	try {
		return axios.post(ep.orderFeedback, messageData);
	} catch (error) {
		const message = error.response.data ? error.response.data.message : error.response.statusText;
		throw new Error(message);
	}
}

async function downloadInvoice() {
	try {
		return axios.get(ep.downloadInvoice);
	} catch (error) {
		const message = error.response.data ? error.response.data.message : error.response.statusText;
		throw new Error(message);
	}
}

async function getInvoiceData() {
	try {
		const res = await axios.get(ep.invoiceData);
		return res.data;
	} catch (error) {
		const message = error.response.data ? error.response.data.message : error.response.statusText;
		throw new Error(message);
	}
}

export default {
	getOrders,
	getDemoOrdersCount,
	create,
	uploadConvert,
	success,
	checkout,
	getPeriodOrder,
	sendFeedback,
	downloadInvoice,
	downloadInvoiceLink: ep.downloadInvoice,
	getInvoiceData,
};
